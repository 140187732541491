<template>
  <div id="testgrouptests" class="fill-height">
    <v-card>
      <!-- Top SVG -->
      <svg
        id="wave"
        style="transform: rotate(180deg); transition: 0.3s"
        viewBox="0 0 1440 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgba(232, 234, 246, 1)" offset="0%"></stop>
            <stop
              stop-color="rgba(244.982, 244.982, 244.982, 1)"
              offset="100%"
            ></stop>
          </linearGradient>
        </defs>
        <path
          style="transform: translate(0, 0px); opacity: 1"
          fill="url(#sw-gradient-0)"
          d="M0,20L40,20C80,20,160,20,240,25C320,30,400,40,480,38.3C560,37,640,23,720,25C800,27,880,43,960,55C1040,67,1120,73,1200,63.3C1280,53,1360,27,1440,21.7C1520,17,1600,33,1680,43.3C1760,53,1840,57,1920,63.3C2000,70,2080,80,2160,78.3C2240,77,2320,63,2400,51.7C2480,40,2560,30,2640,35C2720,40,2800,60,2880,60C2960,60,3040,40,3120,36.7C3200,33,3280,47,3360,45C3440,43,3520,27,3600,31.7C3680,37,3760,63,3840,71.7C3920,80,4000,70,4080,55C4160,40,4240,20,4320,15C4400,10,4480,20,4560,33.3C4640,47,4720,63,4800,58.3C4880,53,4960,27,5040,15C5120,3,5200,7,5280,15C5360,23,5440,37,5520,40C5600,43,5680,37,5720,33.3L5760,30L5760,100L5720,100C5680,100,5600,100,5520,100C5440,100,5360,100,5280,100C5200,100,5120,100,5040,100C4960,100,4880,100,4800,100C4720,100,4640,100,4560,100C4480,100,4400,100,4320,100C4240,100,4160,100,4080,100C4000,100,3920,100,3840,100C3760,100,3680,100,3600,100C3520,100,3440,100,3360,100C3280,100,3200,100,3120,100C3040,100,2960,100,2880,100C2800,100,2720,100,2640,100C2560,100,2480,100,2400,100C2320,100,2240,100,2160,100C2080,100,2000,100,1920,100C1840,100,1760,100,1680,100C1600,100,1520,100,1440,100C1360,100,1280,100,1200,100C1120,100,1040,100,960,100C880,100,800,100,720,100C640,100,560,100,480,100C400,100,320,100,240,100C160,100,80,100,40,100L0,100Z"
        ></path>
      </svg>

      <!-- Content -->
      <v-container
        v-if="
          courses.length > 0 &&
          courseIndex != null &&
          courses[courseIndex] != null &&
          courses[courseIndex].testGroups[testGroupIndex] != null
        "
      >
        <v-row justify="center" align="center" class="mx-4">
          <v-avatar class="mr-2" size="120">
            <img :src="courses[courseIndex].logoImg" />
          </v-avatar>

          <h1 class="text-h4 grey--text text--darken-2">
            {{ courses[courseIndex].courseName }}
          </h1>
        </v-row>

        <v-row justify="center" align="center">
          <!-- Course Tests -->
          <v-col cols="12" sm="12">
            <TestGroupTestsCard
              :superLoading="loading"
              :selectedCourse="courses[courseIndex].courseName"
              :selectedTestGroup="
                courses[courseIndex].testGroups[testGroupIndex]
              "
              :isUserSubscribed="isUserSubscribed"
              @setSuperLoading="setLoading"
            />
          </v-col>
        </v-row>
      </v-container>

      <!-- Bottom SVG -->
      <svg
        id="wave"
        style="transform: rotate(0deg); transition: 0.3s"
        viewBox="0 0 1440 100"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0">
            <stop stop-color="rgba(232, 234, 246, 1)" offset="0%"></stop>
            <stop
              stop-color="rgba(244.982, 244.982, 244.982, 1)"
              offset="100%"
            ></stop>
          </linearGradient>
        </defs>
        <path
          style="transform: translate(0, 0px); opacity: 1"
          fill="url(#sw-gradient-0)"
          d="M0,20L34.3,20C68.6,20,137,20,206,25C274.3,30,343,40,411,38.3C480,37,549,23,617,25C685.7,27,754,43,823,55C891.4,67,960,73,1029,63.3C1097.1,53,1166,27,1234,21.7C1302.9,17,1371,33,1440,43.3C1508.6,53,1577,57,1646,63.3C1714.3,70,1783,80,1851,78.3C1920,77,1989,63,2057,51.7C2125.7,40,2194,30,2263,35C2331.4,40,2400,60,2469,60C2537.1,60,2606,40,2674,36.7C2742.9,33,2811,47,2880,45C2948.6,43,3017,27,3086,31.7C3154.3,37,3223,63,3291,71.7C3360,80,3429,70,3497,55C3565.7,40,3634,20,3703,15C3771.4,10,3840,20,3909,33.3C3977.1,47,4046,63,4114,58.3C4182.9,53,4251,27,4320,15C4388.6,3,4457,7,4526,15C4594.3,23,4663,37,4731,40C4800,43,4869,37,4903,33.3L4937.1,30L4937.1,100L4902.9,100C4868.6,100,4800,100,4731,100C4662.9,100,4594,100,4526,100C4457.1,100,4389,100,4320,100C4251.4,100,4183,100,4114,100C4045.7,100,3977,100,3909,100C3840,100,3771,100,3703,100C3634.3,100,3566,100,3497,100C3428.6,100,3360,100,3291,100C3222.9,100,3154,100,3086,100C3017.1,100,2949,100,2880,100C2811.4,100,2743,100,2674,100C2605.7,100,2537,100,2469,100C2400,100,2331,100,2263,100C2194.3,100,2126,100,2057,100C1988.6,100,1920,100,1851,100C1782.9,100,1714,100,1646,100C1577.1,100,1509,100,1440,100C1371.4,100,1303,100,1234,100C1165.7,100,1097,100,1029,100C960,100,891,100,823,100C754.3,100,686,100,617,100C548.6,100,480,100,411,100C342.9,100,274,100,206,100C137.1,100,69,100,34,100L0,100Z"
        ></path>
      </svg>
    </v-card>
  </div>
</template>

<script>
var dayjs = require("dayjs");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");
dayjs.extend(isSameOrBefore);

import TestGroupTestsCard from "@/components/Public/TestGroup/TestGroupTestsCard.vue";

export default {
  name: "TestGroupTests",
  metaInfo: {
    title: "Test Group Tests",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Student Tests Group Tests Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
  components: { TestGroupTestsCard },
  computed: {
    userData() {
      return this.$store.getters.userData;
    },

    isUserSubscribed() {
      const userDataObj = this.userData;
      if (userDataObj && "subscribedTill" in userDataObj) {
        const userSubscribedTill = dayjs(
          userDataObj.subscribedTill.seconds * 1000
        );
        return dayjs().isSameOrBefore(userSubscribedTill);
      }
      return false;
    },
  },
  data: () => ({
    loading: true,
    courseIndex: null,
    testGroupIndex: null,
    courses: [],
  }),
  methods: {
    setLoading(value) {
      this.loading = value;
    },

    fetchCourses() {
      // get courses array from store
      const coursesArr = this.$store.getters.courses;

      //if not found in store
      if (coursesArr.length <= 0 || typeof coursesArr == "undefined") {
        //get folder names from server
        this.$store
          .dispatch("getMetaDoc")
          .then((res) => {
            if (res) {
              this.courses = res.courses;
              if (
                !res.courses[this.courseIndex] ||
                !res.courses[this.courseIndex].testGroups[this.testGroupIndex]
              ) {
                this.$router.push("/404");
                return;
              }
            }
          })
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.setLoading(false);
          });
      } else {
        // if found in store
        this.courses = coursesArr;
        if (
          !coursesArr[this.courseIndex] ||
          !coursesArr[this.courseIndex].testGroups[this.testGroupIndex]
        ) {
          this.$router.push("/404");
          return;
        }
        this.setLoading(false);
      }
    },
  },
  mounted() {
    const courseIndex = this.$route.params.courseID;
    const testGroupIndex = this.$route.params.testGroupID;

    // check valid courseIndex & testGroupIndex
    if (
      isNaN(courseIndex) ||
      courseIndex.toString().length < 1 ||
      isNaN(testGroupIndex) ||
      testGroupIndex.toString().length < 1
    ) {
      this.$router.push("/404");
      return;
    }

    this.courseIndex = courseIndex;
    this.testGroupIndex = testGroupIndex;

    this.fetchCourses();
  },
};
</script>